import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import swal from 'sweetalert'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')

const firebaseConfig = {
  apiKey: 'AIzaSyD2y0uylVf92WvBm105xhI9i11WhsF9koU',
  authDomain: 'webapps-47f3f.firebaseapp.com',
  projectId: 'webapps-47f3f',
  storageBucket: 'webapps-47f3f.appspot.com',
  messagingSenderId: '736931850508',
  appId: '1:736931850508:web:c7cbbb0d0e53b63ce10129',
  measurementId: 'G-31ZCKEY057',
}

initializeApp(firebaseConfig)

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging()
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload)
  // ...
})

getToken(messaging, {
  vapidKey:
    'BHCsFsoFGtmG_QK5asHsnZHF0RZebBSuJDZLcIYpw88Xb4Mnr1C0rcE88BhP-_cZIMr4PItXKWsl6srKkYVBSlg',
})
  .then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      console.log('Token is:', currentToken)
      localStorage.setItem('tokenFCM', currentToken)
      // ...
    } else {
      // Show permission request UI
      swal({
        title: 'Info',
        text: 'Tolong Aktifkan Notifikasi Browser Anda',
        icon: 'info',
      })
      console.log(
        'No registration token available. Request permission to generate one.',
      )
      // ...
    }
  })
  .catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
    swal({
      title: 'Info',
      text: 'Tolong Aktifkan Notifikasi Browser Anda',
      icon: 'info',
    })
    // ...
  })
