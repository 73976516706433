<template>
  <CFooter>
    <div class="ms-auto">
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} Powered by.</span
      >
      <a href="#">birin243</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
