<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <!-- <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img
          src="https://www.ptppi.co.id/wp-content/uploads/2022/01/PPI-ID-Food.png"
          alt="Logo"
          style="max-width: 100px"
        />
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Settings</CNavLink>
        </CNavItem>
      </CHeaderNav> -->
      <CHeaderNav>
        <!-- <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem> -->
        <CNavItem>
          <CNavLink @click="logout()">
            {{ nama }} | {{ unit }}
            <CIcon class="mx-2" icon="cilLockLocked" size="lg" />
          </CNavLink>
        </CNavItem>
        <!-- <AppHeaderDropdownAccnt /> -->
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import { logo } from '@/assets/brand/PPI-ID-Food.png'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },
  setup() {
    return {
      logo,
      nama: localStorage.getItem('nama'),
      unit: localStorage.getItem('unit'),
    }
  },
  methods: {
    logout() {
      try {
        localStorage.setItem('authenticated', 'false') // Make sure to set as a string
        console.log('Logged out successfully.')
        location.reload()
      } catch (error) {
        console.error('Error logging out:', error)
      }
    },
  },
}
</script>
